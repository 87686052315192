import { render, staticRenderFns } from "./templateCenter.vue?vue&type=template&id=63d79078&scoped=true"
import script from "./templateCenter.vue?vue&type=script&lang=js"
export * from "./templateCenter.vue?vue&type=script&lang=js"
import style0 from "./templateCenter.vue?vue&type=style&index=0&id=63d79078&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63d79078",
  null
  
)

export default component.exports