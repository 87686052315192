<template>
  <div class="page">
    <div class="backTop">
      <div class="textBox">
        <div class="text1">10万+模板免费用</div>
        <div class="text2">轻松制作广告大片</div>
      </div>
    </div>
    <div v-if="templateList.length" class="template-view">
      <div class="template-center flex-col" id="TemplateCenter">
        <!-- <span class="template-center-title">模板中心</span> -->
        <div
          v-if="templateList.length"
          class="template-list flex-row flex-wrap"
        >
          <div
            class="template-item flex-col"
            :style="isFirefox?'width:399rem':''"
            v-for="(item, index) in templateList"
            :key="index"
            @mouseenter="temPlay(index)"
            @mouseleave="temPause(index)"
            @click="onClick_edit(item.template)"
          >
            <video
              oncontextmenu="return false;"
              :id="'temVideo' + index"
              class="video"
              :poster="item.thumbnailUrl"
              loop
              muted
              preload="auto"
            >
              <source :src="item.url" type="video/mp4" />
            </video>
            <span class="title">{{ item.title }}</span>
            <img class="icon" src="@/assets/img/play_icon.png" />
          </div>
          <div
            class="template-item-more flex-row justify-center align-center"
            :style="isFirefox?'width:399rem':''"
            @click="onClick_create"
          >
            •••
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/utils/bus";
/* eslint-disable import/no-cycle */
import { getCanvaTemplateList } from "@/api/request";

export default {
  data() {
    return {
      templateList: [],
      isFirefox: false, // 是否是火狐浏览器
    };
  },
  mounted() {
    this.getCanvaTemplateList();
    bus.$on("refreshTemplateList", () => {
      this.getCanvaTemplateList();
    });
    // 如果是火狐浏览器，解决样式多1px不兼容的问题
    if (navigator.userAgent.indexOf("Firefox") > -1) {
      this.isFirefox = true;
    }
  },
  methods: {
    // 获取模板中心数据
    getCanvaTemplateList() {
      getCanvaTemplateList({}).then((res) => {
        this.templateList = res.templateList;
      });
    },
    temPlay(index) {
      // 获取视频标签
      const video = document.getElementById(`temVideo${index}`);
      // console.log("视频时长:", video.duration);
      // 播放视频
      video.play();
    },
    temPause(index) {
      // 获取视频标签
      const video = document.getElementById(`temVideo${index}`);
      // 停止播放
      video.pause();
      setTimeout(() => {
        video.load();
      }, 10);
    },
    onClick_edit(templateId) {
      this.$canvaUtil.onClickEdit(templateId);
    },
    onClick_create() {
      this.$canvaUtil.onClickCreate(true);
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  min-height: calc(100vh - 233rem);
  background: #fff;
  .backTop{
    height: 519rem;
    width: 100%;
    background: red;
    position: relative;
    top:-70rem;
    background: url('../../assets/img/pagetop.png');
    background-size: 100% 100%;
    .textBox{
      padding: 238rem 0 0 320rem;
      .text1{
        width: 400rem;
        // height: 62rem;
        font-size: 44rem;
        font-weight: 400;
        color: #252927;
        line-height: 62rem;
        letter-spacing: 2rem;
        background: linear-gradient(225deg, #4FACFE 0%, #26CBD3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .text2{
        // width: 240rem;
        // height: 41rem;
        margin-top: 20rem;
        font-size: 29rem;
        font-weight: 400;
        color: #7C7E7F;
        line-height: 41rem;
        letter-spacing: 1rem;
      }
    }
  }
  .template-view {
    width: 100%;

    .template-center {
      z-index: 4;
      width: 1280rem;
      margin: 0rem 320rem 110rem;
      position: relative;
      overflow: hidden;

      .template-center-title {
        width: 144rem;
        height: 50rem;
        color: rgba(37, 41, 39, 1);
        font-size: 36rem;
        line-height: 50rem;
        text-align: left;
        margin-bottom: 4rem;
      }

      .template-list {
        .template-item {
          width: 400rem;
          margin-top: 32rem;
          margin-right: 40rem;
          position: relative;
          cursor: pointer;
          overflow: hidden;

          &:nth-child(3n) {
            margin-right: 0;
          }

          .video {
            width: 400rem;
            height: 225rem;
            border-radius: 8rem;
            // object-fit: fill;
          }

          .title {
            font-size: 16rem;
            font-weight: 500;
            color: #3b3b3b;
            line-height: 24rem;
            margin-top: 16rem;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //用省略号显示
            white-space: nowrap; //不换行
          }

          .icon {
            width: 30rem;
            position: absolute;
            bottom: 56rem;
            right: 16rem;
          }
        }
        .template-item-more {
          width: 400rem;
          height: 225rem;
          background: #F6F7F9;
          border-radius: 8rem;
          margin-top: 32rem;
          cursor: pointer;
          font-size: 30rem;
          color: #B8B9BA;
        }
      }
    }
  }
}
</style>
